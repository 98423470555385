/** @jsx jsx */
import { jsx, Flex, Grid } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import NavMenuDesktop from "../components/navMenuDesktop"
import NavMenuMobile from "../components/navMenuMobile"
import BackgroundImg from "gatsby-background-image"
import Img from "gatsby-image"
import { FiArrowLeft, FiGrid, FiArrowRight } from "react-icons/fi"
import {
  FaTimes,
  FaInfoCircle,
  FaMicrophone,
  FaPlayCircle,
} from "react-icons/fa"
import Footer from "../components/footer"
import { Masonry } from "masonic"
import Modal from "react-modal"
import ReactPlayer from "react-player/lazy"
import SEO from "../components/seo"

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.90)",
    zIndex: 20,
  },
}
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#___gatsby")

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        name
        excerpt
        thumb {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        title
        feat_img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        date(formatString: "DD MMMM, YYYY")
        order
        gallery {
          img {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          credit
        }
        trailer
        links {
          link
          url
        }
        cropPosition
      }
      html
    }
    news: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "news" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            excerpt
            thumb {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            title
            feat_img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date(formatString: "DD MMMM, YYYY")
            order
          }
          fields {
            slug
          }
          html
        }
      }
    }
  }
`

const NewsTemplate = (props, { data }) => {
  //news order
  const currOrder = props.data.markdownRemark.frontmatter.order
  const prevNews = props.data.news.edges.filter(item => {
    if (item.node.frontmatter.order === currOrder - 1) {
      return item.node.fields.slug
    }
    return null
  })
  const nextNews = props.data.news.edges.filter(item => {
    if (item.node.frontmatter.order === currOrder + 1) {
      return item.node.fields.slug
    }
    return null
  })
  //optional chaining
  const prevNewsSlug = prevNews[0]?.node.fields.slug
  const nextNewsSlug = nextNews[0]?.node.fields.slug

  //console.log(nextNewsSlug)
  const galleryItems = props.data.markdownRemark.frontmatter.gallery

  //modal
  const cropPosition = props.data.markdownRemark.frontmatter.cropPosition
  return (
    <>
      <SEO
        title={`${props.data.markdownRemark.frontmatter.name} - News`}
        image={props.data.markdownRemark.frontmatter.feat_img}
      />
      <NavMenuMobile />
      <Grid
        gap={2}
        columns={["none", "2fr 1fr", "3fr 1fr"]}
        sx={{
          // bg: lighten("primary", 0.35),
          bg: "primaryBG",
          px: 3,
        }}
      >
        <section sx={{ minHeight: "100vh" }}>
          <div>
            <h1
              sx={{
                color: "primary",
                mt: ["80px", 2],
              }}
            >
              {props.data.markdownRemark.frontmatter.name}
            </h1>
            <BackgroundImg
              fluid={
                props.data.markdownRemark.frontmatter.feat_img.childImageSharp
                  .fluid
              }
              sx={{
                height: ["200px", "275px", "350px"],
                "&::before": {
                  backgroundPositionY: "top",
                },
                "& img": {
                  objectPosition: "top center !important",
                },
              }}
              style={{
                backgroundPositionY: cropPosition,
              }}
            />
          </div>
          <Flex
            sx={{
              marginTop: 4,
              display: ["block", "flex"],
            }}
          >
            <div
              sx={{
                bg: "white",
                padding: 3,
                width: ["100%", "60%"],
                marginRight: [0, "5%"],
                marginBottom: [3, 4],
                "& blockquote": {
                  color: "mutedText",
                  fontSize: 2,
                  fontStyle: "italic",
                  borderLeft: "10px solid #ccc",
                  borderColor: "primary",
                  margin: "1em 10px",
                  padding: "0.5em 10px",
                },
                "& cite": {
                  color: "primary",
                },
              }}
            >
              <h2>{props.data.markdownRemark.frontmatter.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: props.data.markdownRemark.html,
                }}
              />
            </div>
            <div
              sx={{
                width: ["100%", "35%", null],
              }}
            >
              <nav
                sx={{
                  bg: "primary",
                  px: 3,
                  paddingTop: 3,
                  paddingBottom: 2,
                  mb: 2,
                  fontSize: 4,
                  justifyContent: "center",
                  display: "block",
                  "& a": {
                    color: "white",
                    px: 2,
                  },

                  "& h2": {
                    textAlign: "center",
                    mt: 0,
                    mb: 1,
                    fontSize: 3,
                  },
                }}
              >
                <h2>{props.data.markdownRemark.frontmatter.date}</h2>
                <div sx={{ display: "flex", justifyContent: "center" }}>
                  {prevNewsSlug ? (
                    <Link to={`/news/${prevNewsSlug}`}>
                      <FiArrowLeft />
                    </Link>
                  ) : (
                    <a>
                      <FiArrowLeft
                        sx={{
                          color: "white",
                          opacity: 0.3,
                        }}
                      />
                    </a>
                  )}
                  <Link to="/news">
                    <FiGrid />
                  </Link>
                  {nextNewsSlug ? (
                    <Link to={`/news/${nextNewsSlug}`}>
                      <FiArrowRight />
                    </Link>
                  ) : (
                    <a>
                      <FiArrowRight
                        sx={{
                          color: "white",
                          opacity: 0.3,
                        }}
                      />
                    </a>
                  )}
                </div>
              </nav>
              {props.data.markdownRemark.frontmatter.links &&
                props.data.markdownRemark.frontmatter.links.map(link => {
                  return (
                    <a
                      href={link.url}
                      sx={{
                        display: "inline-block",
                        bg: "primary",
                        color: "white",
                        px: 3,
                        py: 2,
                        mb: 2,
                        textDecoration: "none",
                        "&:hover, &:focus": {
                          bg: "mutedText",
                        },
                      }}
                    >
                      {link.link}
                    </a>
                  )
                })}
            </div>
          </Flex>

          <div sx={{ mb: 3 }}>
            {props.data.markdownRemark.frontmatter.trailer && (
              <div
                className="player-wrapper"
                sx={{
                  position: "relative",
                  paddingTop: "56.25%",
                  mb: 3,
                }}
              >
                <ReactPlayer
                  className="react-player"
                  url={props.data.markdownRemark.frontmatter.trailer}
                  config={{ vimeo: { playerOptions: { controls: 1 } } }}
                  width="100%"
                  height="100%"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
            )}
            {props.data.markdownRemark.frontmatter.gallery && (
              <Masonry
                items={galleryItems}
                render={MasonryCard}
                columnGutter={8}
                //columnWidth={250}
                className="masonry-grid"
              />
            )}
          </div>
          <Link to="/news" sx={{ display: ["block", "none", null] }}>
            Back to News
          </Link>
        </section>
        <section>
          <NavMenuDesktop navPosition="right" />
        </section>
      </Grid>
      <Footer navPosition="right" />
    </>
  )
}
//this is diff to portfolio, prob need to refactor to work in both places
const MasonryCard = ({ index, data: { img, credit }, width }) => {
  const [modalState, setModalState] = useState(false)
  const openModal = src => {
    setModalState(true)
    //console.log("ive oppened a modal")
    //stop bg scrolling when modal open - done in global css above now
  }
  const closeModal = () => {
    setModalState(false)
  }

  return (
    <>
      <div onClick={openModal} sx={{ "&:hover": { cursor: "pointer" } }}>
        <Img fluid={img.childImageSharp.fluid} alt={credit} />
      </div>

      <Modal
        isOpen={modalState}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        style={modalStyles}
        contentLabel="Image Lightbox Modal"
      >
        <figure
          sx={{
            width: "1000px",
            maxWidth: "90vw",
            maxHeight: "90vh",
            textAlign: "center",
          }}
        >
          <FaTimes
            onClick={closeModal}
            sx={{
              color: "#fff",
              fontSize: 4,
              position: "absolute",
              right: 0,
              top: 0,
              cursor: "pointer",
              opacity: 0.7,
              zIndex: 10,
              "&:hover": {
                opacity: 1,
              },
            }}
          />
          <Img
            fluid={img.childImageSharp.fluid}
            alt={credit}
            sx={{
              width: "100%",
              maxWidth: "95vw",
              height: "100%",
              maxHeight: "85vh",
              "& img": {
                objectFit: "contain !important",
              },
            }}
          />
          <figcaption
            sx={{
              backgroundColor: "primary",
              color: "#fff",
              display: "inline-block",
              padding: 2,
              mt: 2,
            }}
          >
            {credit}
          </figcaption>
        </figure>
      </Modal>
    </>
  )
}

export default NewsTemplate
